/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

// File contains the hard-coded version of the client and webapp, will be
// overwritten by CI build, do not move or modify manually!
export const version = '2b419c44daf26ba1485ed3129fb8f9ce0415edc9';

export const versionShort = (() => {
  return version.substring(0, 7);
})();
